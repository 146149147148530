<template>
  <b-overlay :show="loading">
    <div class="row">
      <div class="col-12 col-md-8">
        <div class="card">
          <div class="card-header">
            <h6 class="card-title">
              Información general
            </h6>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>Nombre en español</label>
                  <input
                    v-model="name_es"
                    type="text"
                    class="form-control"
                  >
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>Nombre en inglés</label>
                  <input
                    v-model="name_en"
                    type="text"
                    class="form-control"
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Descripción en español</label>
                  <vue-editor v-model="description_es" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Descripción en inglés</label>
                  <vue-editor v-model="description_en" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <multiple-multimedia-component
                    :field="multimediaField"
                    :options="multimediaField.getOptions()"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Stock</label>
                  <input
                    v-model="stock"
                    type="number"
                    class="form-control"
                  >
                </div>
              </div>

              <div class="col-12">
                <div class="form-group">
                  <label>Tiktok</label>
                  <input
                    v-model="tiktok"
                    type="text"
                    class="form-control"
                  >
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label>Youtube</label>
                  <input
                    v-model="youtube"
                    type="text"
                    class="form-control"
                  >
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label>Instragram</label>
                  <input
                    v-model="instagram"
                    type="text"
                    class="form-control"
                  >
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Precio final</label>
                  <cleave
                    v-model="public_price"
                    :options="numberFormatOptions"
                    class="form-control"
                  />

                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label>Precio del vendedor</label>
                  <cleave
                    v-model="net_price"
                    :options="numberFormatOptions"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label>Precio promocional (Tachado en el sitio)</label>
                  <cleave
                    v-model="fake_price"
                    :options="numberFormatOptions"
                    class="form-control"
                  />
                </div>
              </div>
            </div>

            <hr>

            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Enlace (si se deja en blanco se asigna automáticamente)</label>
                  <input
                    v-model="slug"
                    type="text"
                    class="form-control"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="card">
          <div class="card-header">
            <h6 class="card-title">
              Información adicional
            </h6>
          </div>
          <div class="card-body">
            <div
              class="form-group"
            >
              <label>SKU</label>
              <input
                v-model="SKU"
                type="text"
                class="form-control"
              >
            </div>

            <div
              class="form-group"
            >
              <label>Enviado desde: </label>
              <input
                v-model="shipment_form"
                type="text"
                class="form-control"
              >
            </div>

            <div class="form-group">
              <label>Estado del producto</label>
              <v-select
                v-model="product_status_id"
                label="text"
                :clearable="false"
                :options="productStatuses"
                :reduce="value=>value.getAttr('id')"
                :get-option-label="option=>option.getAttr('name_es')"
              />
            </div>
            <div class="form-group">
              <label>Marca</label>
              <v-select
                v-model="brand_id"
                label="text"
                :clearable="false"
                :options="brands"
                :reduce="value=>value.getAttr('id')"
                :get-option-label="option=>option.getAttr('name_es')"
              />
            </div>
            <div class="form-group">
              <label>Categoría</label>
              <v-select
                v-model="category"
                label="text"
                :clearable="false"
                :options="categories"
                :reduce="value=>value"
                :get-option-label="option=>option.getAttr('name_es')"
              />
            </div>

            <div
              v-if="category && category.getAttr('categories').length>0"
              class="form-group"
            >
              <label>Subcategoría</label>
              <v-select
                v-model="subcategory"
                label="text"
                :clearable="false"
                :options="category.getAttr('categories')"
                :reduce="value=>value"
                :get-option-label="option=>option.name_es"
              />
            </div>

            <div
              v-if="hasSizeProp"
              class="form-group"
            >
              <label>Talla</label>
              <input
                v-model="size"
                type="text"
                class="form-control"
              >
            </div>
            <!--          <div class="form-group">
            <label>Categoría</label>
            <input
              type="text"
              class="form-control"
            >
          </div>-->

            <div class="form-group">
              <label>Etiquetas (para filtros)</label>
              <v-select
                v-model="product_tags"
                label="text"
                :clearable="false"
                multiple
                :options="tags"
                :reduce="value=>value.getAttr('id')"
                :get-option-label="option=>option.getAttr('name_es')"
              />
            </div>

            <div
              class="form-group"
            >
              <label>Texto para etiqueta (español): </label>
              <input
                v-model="tag_text_es"
                type="text"
                class="form-control"
              >
            </div>

            <div
              class="form-group"
            >
              <label>Texto para etiqueta (inglés): </label>
              <input
                v-model="tag_text_en"
                type="text"
                class="form-control"
              >
            </div>

            <div class="d-flex justify-content-between align-items-center my-1">
              <label>Fast Shipping</label>
              <b-form-checkbox
                v-model="fast_shipping"
                switch
              />
            </div>

            <div
              class="form-group"
            >
              <label>Tiempo de entrega: </label>
              <input
                v-model="delivery_time"
                type="number"
                class="form-control"
              >
            </div>

            <div
              class="form-group"
            >
              <label>Medidas: </label>
              <input
                v-model="dimensions"
                type="text"
                class="form-control"
              >
            </div>

          </div>
        </div>

        <div class="card">
          <div class="card-header">
            <h6 class="card-title">
              Configuración del producto
            </h6>
          </div>
          <div class="card-body">
            <div class="form-group">
              <label>Visibilidad en tienda</label>
              <v-select
                v-model="status"
                label="text"
                :clearable="false"
                :options="statuses"
                :reduce="value=>value.value"
                :get-option-label="option=>option.label"
              />
            </div>

            <div class="d-flex justify-content-between align-items-center my-1">
              <label>Aplica cashback</label>
              <b-form-checkbox
                v-model="apply_cashback"
                switch
              />
            </div>
            <div class="d-flex justify-content-between align-items-center my-1">
              <label>Producto promocionable</label>
              <b-form-checkbox
                v-model="promotable"
                switch
              />
            </div>

            <!--            <div class="d-flex justify-content-between align-items-center my-1">
              <label>Mostrar en tienda</label>
              <b-form-checkbox
                v-model="status"
                switch
              />
            </div>-->
          </div>
        </div>

        <b-button
          variant="primary"
          class="mb-75"
          block
          @click="save"
        >
          Guardar
        </b-button>

        <!-- Button: DOwnload -->
        <b-button
          variant="danger"
          class="mb-75"
          block
          @click="$router.replace('/products')"
        >
          Cancelar edición
        </b-button>

      </div>

      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h6 class="card-title">
              Variantes
            </h6>
            <button
              class="btn btn-primary"
              @click="addProductVariantGroup"
            >
              <feather-icon icon="PlusIcon" />
            </button>
          </div>
          <div class="card-body">
            <div
              v-for="(group, index) of product_variant_groups"
              :key="index"
              class="row"
            >
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>Nombre del grupo de variantes (Español)</label>
                  <input
                    v-model="group.name_es"
                    type="text"
                    class="form-control"
                  >
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>Nombre del grupo de variantes (Inglés)</label>
                  <input
                    v-model="group.name_en"
                    type="text"
                    class="form-control"
                  >
                </div>
              </div>

              <div class="col-12">
                <div class="table-responsive">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>Nombre (español)</th>
                        <th>Nombre (inglés)</th>
                        <th>Precio público</th>
                        <th>Stock</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(variant, indexVariant) of group.product_variants"
                        :key="indexVariant"
                      >
                        <td>
                          <input
                            v-model="variant.name_es"
                            type="text"
                            class="form-control"
                          >
                        </td>
                        <td>
                          <input
                            v-model="variant.name_en"
                            type="text"
                            class="form-control"
                          >
                        </td>
                        <td>
                          <cleave
                            v-model="variant.public_price"
                            :options="numberFormatOptions"
                            class="form-control"
                          />
                        </td>
                        <td>
                          <input
                            v-model="variant.stock"
                            type="number"
                            class="form-control"
                          >
                        </td>
                        <td>
                          <button
                            class="btn btn-danger"
                            @click="deleteVariant(indexVariant)"
                          >
                            <feather-icon
                              icon="TrashIcon"
                            />
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import MultipleMultimediaComponent from '@/dc-it/components/inputs/MultipleMultimediaComponent.vue'
import MultipleMultimedia from '@/dc-it/types/MultipleMultimedia'
import { BOverlay, BFormCheckbox, BButton } from 'bootstrap-vue'
import ProductStatus from '@/dc-it/models/ProductStatus'
import Brand from '@/dc-it/models/Brand'
import Category from '@/dc-it/models/Category'
import vSelect from 'vue-select'
import Tag from '@/dc-it/models/Tag'
import Cleave from 'vue-cleave-component'
import GeneralService from '@/dc-it/services/GeneralService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Product from '@/dc-it/models/Product'

export default {
  name: 'ProductFormView',
  components: {
    VueEditor,
    MultipleMultimediaComponent,
    BOverlay,
    vSelect,
    BFormCheckbox,
    BButton,
    Cleave,
  },
  data() {
    return {
      numberFormatOptions: {
        prefix: '$ ',
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        rawValueTrimPrefix: true,
      },
      statuses: [
        { value: 0, label: 'Inactivo (No mostrar en tienda)' },
        { value: 1, label: 'En revisión pendiente' },
        { value: 2, label: 'Activo y Disponible (Mostrar en tienda)' },
        { value: 3, label: 'Out Of Stock (Mostrar en tienda)' },
        { value: 4, label: 'Pre-Order (Mostrar en tienda)' },
      ],
      loading: true,
      productStatuses: [],
      brands: [],
      categories: [],
      tags: [],
      id: null,
      multimediaField: new MultipleMultimedia('Imágenes', 'Imágenes'),
      name_es: '',
      name_en: '',
      description_es: '',
      description_en: '',
      product_multimedia: [],
      tiktok: '',
      youtube: '',
      instagram: '',
      public_price: null,
      net_price: null,
      fake_price: null,
      slug: '',
      product_status_id: null,
      brand_id: null,
      category: null,
      subcategory: null,
      size: '',
      product_tags: [],
      apply_cashback: false,
      promotable: false,
      status: 2,
      SKU: '',
      shipment_form: '',
      product_variant_groups: [],
      tag_text_es: '',
      tag_text_en: '',
      stock: 1,
      delivery_time: null,
      dimensions: '',
      fast_shipping: false,
    }
  },
  computed: {
    hasSizeProp() {
      let result = false

      if (this.category) {
        if (this.category.getAttr('apply_sizes', false).toString() === '1') {
          result = true
        } else if (this.subcategory) {
          if (this.subcategory.apply_sizes.toString() === '1') {
            result = true
          }
        }
      }

      return result
    },
  },
  mounted() {
    this.loadInitData()
  },
  methods: {
    async loadInitData() {
      this.loading = true
      this.productStatuses = await ProductStatus.where('id', '>', 0).get()
      this.brands = await Brand.where('id', '>', 0).get()
      this.categories = await Category.with(['categories']).where('category_id', '=', null).get()
      this.tags = await Tag.where('id', '>', 0).get()

      if (this.$route.params.id) {
        const ps = await Product.with(['product_tags']).where('id', '=', this.$route.params.id).get()
        if (ps.length !== 1) {
          this.loading = false
          this.$router.replace('/not-found')
          return
        }
        const p = ps[0]
        this.multimediaField.setValue(p.getAttr('multimedia', false))
        this.id = p.getAttr('id')
        this.name_es = p.getAttr('name_es', false)
        this.name_en = p.getAttr('name_en')
        this.description_es = p.getAttr('description_es')
        this.description_en = p.getAttr('description_en')
        this.tiktok = p.getAttr('tiktok_url')
        this.youtube = p.getAttr('youtube_url')
        this.instagram = p.getAttr('instagram_url')
        this.public_price = p.getAttr('public_price', false)
        this.net_price = p.getAttr('net_price', false) || ''
        this.fake_price = p.getAttr('fake_public_price', false) || ''
        this.slug = p.getAttr('slug')
        this.product_status_id = p.getAttr('product_status_id')
        this.brand_id = p.getAttr('brand_id')
        this.category = this.categories.find(category => category.getAttr('id').toString() === p.getAttr('category_id').toString())
        this.subcategory = p.getAttr('subcategory', false).toJSON()
        this.size = p.getAttr('size')
        this.apply_cashback = p.getAttr('apply_cashback', false).toString() === '1'
        this.promotable = p.getAttr('promotable', false).toString() === '1'
        this.status = p.getAttr('status', false)
        this.product_tags = (p.getAttr('product_tags').map(tag => tag.tag_id))
        this.SKU = p.getAttr('SKU')
        this.shipment_form = p.getAttr('shipment_form')
        this.product_variant_groups = p.getAttr('product_variant_groups')
        this.tag_text_es = p.getAttr('tag_text_es')
        this.tag_text_en = p.getAttr('tag_text_en')
        this.stock = p.getAttr('stock')
        this.delivery_time = p.getAttr('delivery_time')
        this.dimensions = p.getAttr('dimensions')
        this.fast_shipping = p.getAttr('fast_shipping', false).toString() === '1'
      }

      this.loading = false
    },
    save() {
      if (!this.name_es || this.name_es.trim() === '') {
        this.showError('Ingrese el nombre en español del producto')
        return
      }
      /* if (!this.name_en || this.name_en.trim() === '') {
        this.showError('Ingrese el nombre en inglés del producto')
        return
      } */
      if (!this.description_es || this.description_es.trim() === '') {
        this.showError('Ingrese la descripción en español del producto')
        return
      }
      if (!this.stock || Number.isNaN(Number(this.stock))) {
        this.showError('Ingrese el stock del producto')
        return
      }
      /* if (!this.description_en || this.description_en.trim() === '') {
        this.showError('Ingrese la descripción en inglés del producto')
        return
      } */
      if (this.multimediaField.getValue().length === 0) {
        this.showError('Agregue por lo menos una imagen')
        return
      }
      if (!this.public_price || Number.isNaN(Number(this.public_price))) {
        this.showError('Ingrese el precio público')
        return
      }
      if (!this.product_status_id) {
        this.showError('Seleccione un estado del producto')
        return
      }
      if (!this.brand_id) {
        this.showError('Seleccione una marca para producto')
        return
      }
      if (!this.category) {
        this.showError('Seleccione una categoría del producto')
        return
      }

      if (this.category.getAttr('categories').length > 0) {
        if (!this.subcategory) {
          this.showError('Seleccione una subcategoría')
          return
        }
      }

      /* if (this.category.getAttr('apply_sizes', false).toString() === '1') {
       if (!this.size || this.size.toString().trim() === '') {
          this.showError('Ingrese la talla/tamaño del producto')
          return
        }
      } */

      this.loading = true
      const data = {
        id: this.id,
        name_es: this.name_es,
        name_en: this.name_en,
        brand_id: this.brand_id,
        category_id: this.category.getAttr('id'),
        subcategory_id: this.category.getAttr('categories').length > 0 ? this.subcategory.id : this.category.getAttr('id'),
        // size: this.category.getAttr('apply_sizes', false).toString() === '1' ? this.size : '',
        size: this.size ?? '',
        apply_cashback: this.apply_cashback,
        description_es: this.description_es,
        description_en: this.description_en,
        product_status_id: this.product_status_id,
        promotable: this.promotable,
        public_price: this.public_price,
        net_price: this.net_price,
        fake_public_price: this.fake_price,
        internal_product: true,
        customer_id: null,
        status: this.status,
        titkok_url: this.tiktok,
        instagram_url: this.instagram,
        youtube_url: this.youtube,
        stock: this.stock,
        slug: this.slug,
        product_multimedia: this.multimediaField.getValue().map(multimedia => multimedia.toJSON()),
        product_tags: this.product_tags,
        SKU: this.SKU,
        shipment_form: this.shipment_form,
        product_variant_groups: this.product_variant_groups,
        tag_text_es: this.tag_text_es,
        tag_text_en: this.tag_text_en,
        delivery_time: this.delivery_time,
        dimensions: this.dimensions,
        fast_shipping: this.fast_shipping,
      }

      GeneralService.getInstance().save('products', data).then(response => {
        this.loading = false
        if (response.result === 'success') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Éxito',
              icon: 'CheckIcon',
              text: 'Se guardó correctamente el producto',
              variant: 'success',
            },
          })
          this.$router.replace('/products')
        } else {
          this.showError('Ha ocurrido un error al guardar el producto')
        }
      })
    },
    addProductVariantGroup() {
      if (this.product_variant_groups.length === 0) {
        this.product_variant_groups = [{
          name_es: '',
          name_en: '',
          product_variants: [
            {
              name_es: '',
              name_en: '',
              public_price: null,
              stock: null,
            },
          ],
        }]
      } else {
        this.product_variant_groups[0].product_variants.push({
          name_es: '',
          name_en: '',
          public_price: null,
          stock: null,
        })
      }
    },
    deleteVariant(index) {
      if (this.product_variant_groups[0].product_variants.length === 1) {
        this.product_variant_groups = []
      } else {
        this.product_variant_groups[0].product_variants.splice(index, 1)
      }
    },
    showError(text) {
      this.$swal({
        title: 'Error',
        text,
        showCancelButton: false,
        confirmButtonText: 'Ok',
        confirmButtonColor: '#41b882',
      })
    },
  },
}
</script>

<style scoped>

</style>
